import React, { useState, useEffect } from "react";
import { login } from "../../routes/auth";
import { useNavigate } from "react-router-dom";
import { initializeCurrencyData } from "../../routes/auth";
import { getUSDEUR } from "../../routes/trading";

// Auto-login function to refresh token using stored credentials
const autoLogin = async (
  storedUsername,
  storedPassword,
  setLoading,
  navigate,
  setError
) => {
  try {
    setLoading(true);
    const userData = await login(storedUsername, storedPassword);
    console.log("Auto-login successful:", userData);

    getUSDEUR();
    initializeCurrencyData();
    
    localStorage.setItem("token", userData.token); // Refresh the token
    navigate("/dashboard");
  } catch (err) {
    console.error("Auto-login failed: ", err.message);
    setError("Auto-login failed, please log in manually.");
    // Clear invalid credentials from localStorage
    localStorage.removeItem("username");
    localStorage.removeItem("password");
  } finally {
    setLoading(false);
  }
};

const PortailClient = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate(); // For navigation after login

  // Check if login identifiers and token exist in localStorage on component mount
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");

    const attemptAutoLogin = async () => {
      if (savedUsername && savedPassword) {
        // If login identifiers are found, auto-attempt login to refresh token
        await autoLogin(
          savedUsername,
          savedPassword,
          setLoading,
          navigate,
          setError
        );
      } else {
        const token = localStorage.getItem("token");
        if (token) {
          if (isMounted) {
            // Only update the state if the component is still mounted
            setTimeout(() => {
              getUSDEUR();
              initializeCurrencyData();
              navigate("/dashboard"); // Redirect to the dashboard if only token is present
            }, 0);
          }
        } else {
          console.log("No valid session found, please log in.");
        }
      }
    };

    attemptAutoLogin();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [navigate]);

  // Handle form submit and login
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userData = await login(username, password); // Call the login function
      console.log("Login successful:", userData);

      localStorage.setItem("token", userData.token); // Always store the token
      localStorage.setItem("username", username);

      if (rememberMe) {
        localStorage.setItem("password", password); // Store password if "Remember Me" is checked
      } else {
        // Clear username and password if "Remember Me" is not checked
        localStorage.removeItem("password");
      }

      getUSDEUR();
      initializeCurrencyData();

      // Redirect the user to the dashboard after successful login
      navigate("/dashboard");
    } catch (err) {
      setError("Login failed: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="card login-card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title">Connexion</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} className="d-flex flex-column h-100">
            <div className="flex-grow-1">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Nom d'utilisateur
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            {error && <div className="text-danger">{error}</div>}
            <div className="form-footer mt-auto">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? "Se connecter..." : "Se connecter"}
              </button>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Se souvenir de moi
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="logo-container">
        <img src="img/MGBsolution_logo_blanc.png" alt="Logo" />
      </div>
    </div>
  );
};

export default PortailClient;
