import { fetchWithAuth } from "./fetchWithAuth";

// Fetch open orders with caching
export async function getOpenOrders() {
  const cacheKey = "openOrdersData";
  const timestampKey = "openOrdersTimestamp";

  if (localStorage.getItem(cacheKey) && isCacheValid(timestampKey)) {
    return JSON.parse(localStorage.getItem(cacheKey));
  } else {
    const token = localStorage.getItem("token");
    const response = await fetchWithAuth(`/user/myfxbook/open-orders`, {
      method: "GET",
      headers: { authorization: token },
    });

    if (!response.ok) throw new Error("Failed to fetch open orders");

    const data = await response.json();
    localStorage.setItem(cacheKey, JSON.stringify(data));
    localStorage.setItem(timestampKey, new Date().getTime().toString());

    return data;
  }
}

// Fetch open trades
export async function getOpenTrades() {
  const cacheKey = "openTradesData";
  const timestampKey = "openTradesTimestamp";

  if (localStorage.getItem(cacheKey) && isCacheValid(timestampKey)) {
    return JSON.parse(localStorage.getItem(cacheKey));
  } else {
    const token = localStorage.getItem("token");
    const response = await fetchWithAuth(`/user/myfxbook/open-trades`, {
      method: "GET",
      headers: { authorization: token },
    });

    if (!response.ok) throw new Error("Failed to fetch open trades");

    const data = await response.json();
    localStorage.setItem(cacheKey, JSON.stringify(data));
    localStorage.setItem(timestampKey, new Date().getTime().toString());

    return data;
  }
}

// Validate cache data
function isCacheValid(timestampKey) {
  const cachedTimestamp = localStorage.getItem(timestampKey);
  return (
    cachedTimestamp &&
    new Date().getTime() - parseInt(cachedTimestamp, 10) < 86400000
  ); // 24 hours
}

// Function to fetch Trading History with caching
export async function getTradingHistory() {
  const cacheKey = "tradingHistoryData";
  const timestampKey = "tradingHistoryTimestamp";

  if (localStorage.getItem(cacheKey) && isCacheValid(timestampKey)) {
    return JSON.parse(localStorage.getItem(cacheKey));
  } else {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithAuth(`/user/myfxbook/history`, {
        method: "GET",
        headers: {
          authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      localStorage.setItem(cacheKey, JSON.stringify(data.history));
      localStorage.setItem(timestampKey, new Date().getTime().toString());

      return data;
    } catch (error) {
      console.error("Failed to fetch trading history:", error);
      throw error;
    }
  }
}

// Function to fetch Trading Data with caching
export async function getTradingData() {
  const cacheKey = "tradingData";
  const timestampKey = "tradingDataTimestamp";

  if (localStorage.getItem(cacheKey) && isCacheValid(timestampKey)) {
    return JSON.parse(localStorage.getItem(cacheKey));
  } else {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithAuth(`/user/myfxbook/trading-data`, {
        method: "GET",
        headers: {
          authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = (await response.json()).dataDaily.flat().map((entry) => ({
        date: entry.date,
        balance: entry.balance,
        floatingPL: entry.floatingPL,
        profit: entry.profit,
        growthEquity: entry.growthEquity,
      }));

      // Store the data in local storage with a timestamp
      localStorage.setItem(cacheKey, JSON.stringify(data));
      localStorage.setItem(timestampKey, new Date().getTime().toString());

      return data;
    } catch (error) {
      console.error("Failed to fetch trading data:", error);
      throw error;
    }
  }
}

// Function to fetch Trading Data with caching
export async function getUSDEUR() {
  if (localStorage.getItem("usdEurRate")) {
    return JSON.parse(localStorage.getItem("usdEurRate"));
  } else {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithAuth(`/eur-usd-rate`, {
        method: "GET",
        headers: {
          authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      // Store the data in local storage with a timestamp
      localStorage.setItem("usdEurRate", JSON.stringify(data));

      return data;
    } catch (error) {
      console.error("Failed to fetch trading data:", error);
      throw error;
    }
  }
}

// Function to convert a value to the user's favorite currency using stored exchange rates
export function convertToFavoriteCurrency(value) {
  try {
    // Get the user's favorite currency from local storage
    const favoriteCurrency = localStorage.getItem('favoriteCurrency') || 'EUR';

    // Get the default currency stored in local storage
    const defaultCurrency = localStorage.getItem('defaultCurrency') || 'EUR';

    // If the favorite currency is the same as the default currency, return the original value
    if (favoriteCurrency === defaultCurrency) {
      return value.toFixed(2);
    }

    // Get the exchange rate for USD to EUR from local storage
    const usdEurRate = JSON.parse(localStorage.getItem('usdEurRate'));

    // Ensure that the rate exists in the local storage
    if (!usdEurRate) {
      console.error('Exchange rate not found in local storage');
      return value; // Return the original value if the rate is not found
    }

    // Perform the conversion based on the currencies
    let convertedValue = value;
    if (defaultCurrency === 'EUR' && favoriteCurrency === 'USD') {
      convertedValue = value / usdEurRate.eurUsdRate; // Convert EUR to USD
    } else if (defaultCurrency === 'USD' && favoriteCurrency === 'EUR') {
      convertedValue = value * usdEurRate.eurUsdRate; // Convert USD to EUR
    }

    return convertedValue.toFixed(2);
  } catch (error) {
    console.error('Error converting to favorite currency:', error);
    return value; // Return the original value in case of an error
  }
}
