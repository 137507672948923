import React from 'react';
import Header from './header'; // Adjust path based on your structure

const Admin = () => {
  return (
    <div>
      <Header />
      <div className="container">
        {/* Add your dashboard content here */}
        <h1>Welcome to the Admin dashboard</h1>
      </div>
    </div>
  );
};

export default Admin;
