import { fetchWithAuth } from './fetchWithAuth';

const BASE_URL = 'https://serene-cliffs-97883-705a00c0a7b2.herokuapp.com/api';

// Function to handle login
export async function login(username, password) {
  try {
    const response = await fetch(`${BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: username, password }),
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Nom d\'utilisateur ou mot de passe incorrect');
    }

    localStorage.setItem('username', username);
    localStorage.setItem('token', data.token);
    localStorage.setItem('isAdmin', data.isAdmin);
    localStorage.setItem('myfxbookID', data.myfxbookID);

    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}

// Function to change password
export async function changePassword(oldPassword, newPassword) {
  try {
    const token = localStorage.getItem('token');
    const response = await fetchWithAuth(`/change-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Failed to change password');
    }

    console.log('Password changed successfully');
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
}

// Validate cache data
function isCacheValid(timestampKey) {
  const cachedTimestamp = localStorage.getItem(timestampKey);
  return cachedTimestamp && (new Date().getTime() - parseInt(cachedTimestamp, 10)) < 86400000; // 24 hours
}

export async function getMyfxbookAccount() {
  const cacheKey = "myfxbookAccountData";
  const timestampKey = "myfxbookAccountTimestamp";

  // Check if the cached data exists and is valid
  if (localStorage.getItem(cacheKey) && isCacheValid(timestampKey)) {
    return JSON.parse(localStorage.getItem(cacheKey));
  } else {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithAuth(`/user/myfxbook/account`, {
        method: "GET",
        headers: {
          authorization: token,
        },
      });

      const responseText = await response.text();
      const data = JSON.parse(responseText);

      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch accounts");
      }

      // Store the data in local storage with a timestamp
      localStorage.setItem(cacheKey, JSON.stringify(data));
      localStorage.setItem(timestampKey, new Date().getTime().toString());

      return data;
    } catch (error) {
      console.error("Error fetching accounts:", error);
      throw error;
    }
  }
}

// Function to get MGBSolution account data
export async function getMGBSolutionAccount() {
  const cacheKey = "mgbSolutionAccountData";
  const timestampKey = "mgbSolutionAccountTimestamp";

  // Check if the cached data exists and is valid
  if (localStorage.getItem(cacheKey) && isCacheValid(timestampKey)) {
    return JSON.parse(localStorage.getItem(cacheKey));
  } else {
    try {
      const token = localStorage.getItem("token");
      const response = await fetchWithAuth(`/user/mgbsolution/account`, {
        method: "GET",
        headers: {
          authorization: token,
        },
      });

      const responseText = await response.text();
      const data = JSON.parse(responseText);

      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch MGBSolution account data");
      }

      // Store the data in local storage with a timestamp
      localStorage.setItem(cacheKey, JSON.stringify(data));
      localStorage.setItem(timestampKey, new Date().getTime().toString());

      return data;
    } catch (error) {
      console.error("Error fetching MGBSolution account data:", error);
      throw error;
    }
  }
}

// Function to initialize the currency data in local storage at login
export async function initializeCurrencyData() {
  try {
    // Fetch the account data using the existing getMyfxbookAccount function
    const accountData = await getMyfxbookAccount();

    // Retrieve the currency from the account data
    const currency = accountData.currency;

    if (currency) {
      // Store the favorite currency and the default currency in local storage
      if (!localStorage.getItem("favoriteCurrency")) {
        localStorage.setItem("favoriteCurrency", currency);
      }
      if (!localStorage.getItem("defaultCurrency")) {
        localStorage.setItem("defaultCurrency", currency);
      }
    }
  } catch (error) {
    console.error("Error initializing currency data:", error);
  }
}

// Function to get the favorite currency from local storage
export function getFavoriteCurrency() {
  return localStorage.getItem("favoriteCurrency") || "EUR"; // Default to "EUR" if not set
}

// Function to get the currency symbol from the favorite currency in local storage
export function getCurrencySymbol() {
  const favoriteCurrency = getFavoriteCurrency();

  switch (favoriteCurrency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    default:
      return '?'; // Return a default symbol if the currency is not recognized
  }
}
