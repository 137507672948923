import React, { useState, useEffect } from "react";
import {
  getOpenTrades,
  getOpenOrders,
  getTradingHistory,
  convertToFavoriteCurrency,
} from "../../routes/trading";
import { getCurrencySymbol } from "../../routes/auth";
import Header from "./header"; // Adjust path based on your structure

const TradingActivity = () => {
  const [activeTab, setActiveTab] = useState("Transactions Ouvertes");
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({ netProfit: 0, swap: 0, interest: 0 });

  useEffect(() => {
    const fetchData = async () => {
      let result;
      if (activeTab === "Transactions Ouvertes") {
        result = await getOpenTrades();
        result = result.openTrades;
      } else if (activeTab === "Ordres Ouverts") {
        result = await getOpenOrders();
      } else if (activeTab === "Historique") {
        result = await getTradingHistory();
        console.log(result)
      }

      // Ensure that the data is always an array
      setData(Array.isArray(result) ? result : []);
    };

    fetchData();
  }, [activeTab]);

  useEffect(() => {
    // Calculate the totals only for "Transactions Ouvertes" and "Historique"
    if (activeTab === "Transactions Ouvertes") {
      const netProfitTotal = data.reduce(
        (sum, item) => sum + (item.profit || 0),
        0
      );
      const swapTotal = data.reduce((sum, item) => sum + (item.swap || 0), 0);
      setTotals({ netProfit: netProfitTotal, swap: swapTotal, interest: 0 });
    } else if (activeTab === "Historique") {
      const netProfitTotal = data.reduce(
        (sum, item) => sum + (item.profit || 0),
        0
      );
      const interestTotal = data.reduce(
        (sum, item) => sum + (item.interest || 0),
        0
      );
      setTotals({
        netProfit: netProfitTotal,
        swap: 0,
        interest: interestTotal,
      });
    } else {
      setTotals({ netProfit: 0, swap: 0, interest: 0 });
    }
  }, [data, activeTab]);

  const renderTableHeaders = () => {
    switch (activeTab) {
    case "Transactions Ouvertes":
      return (
        <tr>
          <th>Open Date</th>
          <th>Symbol</th>
          <th>Action</th>
          <th>Open Price</th>
          <th>SL (Price)</th>
          <th>TP (Price)</th>
          <th>Lots</th>
          <th className="align-right">Swap</th> {/* Right-aligned */}
          <th className="align-right">Net Profit</th> {/* Right-aligned */}
        </tr>
      );
    case "Ordres Ouverts":
      return (
        <tr>
          <th>Open Date</th>
          <th>Symbol</th>
          <th>Action</th>
          <th>Open Price</th>
          <th>SL (Price)</th>
          <th>TP (Price)</th>
          <th>Lots</th>
        </tr>
      );
    case "Historique":
      return (
        <tr>
          <th>Open Date</th>
          <th>Close Date</th>
          <th>Symbol</th>
          <th>Action</th>
          <th>Open Price</th>
          <th>Close Price</th>
          <th>TP (Price)</th>
          <th>SL (Price)</th>
          <th>Lots</th>
          <th className="align-right">Interest</th> {/* Right-aligned */}
          <th className="align-right">Net Profit</th> {/* Right-aligned */}
        </tr>
      );
    default:
      return null;
  }
  };

  const renderTableRows = () => {
    return data.map((item, index) => (
      <tr key={index}>
        {activeTab === "Transactions Ouvertes" && (
          <>
            <td className="text">{item.openTime}</td>
            <td className="textsymbol">{item.symbol}</td>
            <td className="text">{item.action}</td>
            <td className="text">{item.openPrice}</td>
            <td className="text">{item.sl}</td>
            <td className="text">{item.tp}</td>
            <td className="text">{item.sizing.value}</td>
            <td className={`align-right ${item.swap < 0 ? "negative" : "positive"}`}>
            {convertToFavoriteCurrency(item.swap)} {getCurrencySymbol()}
            </td>
            <td className={`align-right ${item.profit < 0 ? "negative" : "positive"}`}>
            {convertToFavoriteCurrency(item.profit)} {getCurrencySymbol()}
            </td>
          </>
        )}
        {activeTab === "Ordres Ouverts" && (
          <>
            <td className="text">{item.openTime}</td>
            <td className="textsymbol">{item.symbol}</td>
            <td className="text">{item.action}</td>
            <td className="text">{item.openPrice}</td>
            <td className="text">{item.sl}</td>
            <td className="text">{item.tp}</td>
            <td className="text">{item.sizing.value}</td>
          </>
        )}
        {activeTab === "Historique" && (
          <>
            <td className="text">{item.openTime}</td>
            <td className="text">{item.closeTime}</td>
            <td className="textsymbol">{item.symbol}</td>
            <td className="text">{item.action}</td>
            <td className="text">{item.openPrice}</td>
            <td className="text">{item.closePrice}</td>
            <td className="text">{item.tp}</td>
            <td className="text">{item.sl}</td>
            <td className="text">{item.sizing.value}</td>
            <td className={`align-right ${item.interest < 0 ? "negative" : "positive"}`}>
            {convertToFavoriteCurrency(item.interest)} {getCurrencySymbol()}
            </td>
            <td className={`align-right ${item.profit < 0 ? "negative" : "positive"}`}>
            {convertToFavoriteCurrency(item.profit)} {getCurrencySymbol()}
            </td>
          </>
        )}
      </tr>
    ));
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div className="tabs-container">
          <span className="trading-activity-title">Activité de Trading</span>
          <div className="tabs">
            <button
              className={`tab-button ${
                activeTab === "Transactions Ouvertes" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Transactions Ouvertes")}
            >
              Transactions Ouvertes
            </button>
            <button
              className={`tab-button ${
                activeTab === "Ordres Ouverts" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Ordres Ouverts")}
            >
              Ordres Ouverts
            </button>
            <button
              className={`tab-button ${
                activeTab === "Historique" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Historique")}
            >
              Historique
            </button>
          </div>
        </div>
        <div className="table-container">
          <table className="trading-table">
            <thead>{renderTableHeaders()}</thead>
            <tbody>{renderTableRows()}</tbody>
            {(activeTab === "Transactions Ouvertes" ||
              activeTab === "Historique") && (
              <tfoot>
                <tr>
                  <td
                    className="text"
                    colSpan={activeTab === "Transactions Ouvertes" ? "7" : "9"}
                  >
                    Total
                  </td>
                  {activeTab === "Transactions Ouvertes" && (
                    <>
                      <td className={`align-right ${totals.swap < 0 ? "negative" : "positive"}`}>
                      {convertToFavoriteCurrency(totals.swap)} {getCurrencySymbol()}
                      </td>
                      <td
                        className={`align-right ${totals.netProfit < 0 ? "negative" : "positive"}`}
                      >
                        {convertToFavoriteCurrency(totals.netProfit)} {getCurrencySymbol()}
                      </td>
                    </>
                  )}
                  {activeTab === "Historique" && (
                    <>
                      <td
                        className={`align-right ${totals.interest < 0 ? "negative" : "positive"}`}
                      >
                        {convertToFavoriteCurrency(totals.interest)} {getCurrencySymbol()}
                      </td>
                      <td
                        className={`align-right ${totals.netProfit < 0 ? "negative" : "positive"}`}
                      >
                        {convertToFavoriteCurrency(totals.netProfit)} {getCurrencySymbol()}
                      </td>
                    </>
                  )}
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 100%;
          min-Width: 760px;
          margin: 0 auto;
          padding: 20px;
        }
        .tabs-container {
          display: flex;
          align-items: center;
          padding: 10px;
          border-bottom: 2px solid #e0e0e0;
        }
        .trading-activity-title {
          font-weight: bold;
          color: black;
          margin-right: 20px;
        }
        .tabs {
          display: flex;
          background-color: white;
          border-radius: 5px;
        }
        .tab-button {
          background: none;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          color: #333;
        }
        .tab-button.active {
          color: #004aad;
          font-weight: bold;
          border-bottom: 2px solid #004aad;
        }
        .table-container {
          overflow-x: auto;
          margin-top: 20px;
          background-color: white;
          border: 0px solid #e0e0e0;
        }
        .trading-table {
          width: 100%;
          border-collapse: collapse;
          border: 0px solid #e0e0e0;
        }
        .trading-table .align-right {
    text-align: right; /* Align content to the right */
  }
        .trading-table th {
          padding: 10px;
          text-align: left;
          background-color: #f9f9f9;
          color: black;
          border: 1px solid #e0e0e0;
        }
        .trading-table td {
          padding: 10px;
          text-align: left;
          border: 1px solid #e0e0e0;
        }
        .positive {
          color: green;
        }
        .negative {
          color: red;
        }
        .text {
          color: black;
        }
        .textsymbol {
          color: blue;
          text-decoration: none;
        }
        .textsymbol:hover {
          color: blue;
          text-decoration: underline;
        }
        .trading-table tfoot td {
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default TradingActivity;
