import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Header = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem('username') || '?'; // Display user’s initial or placeholder
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/portail-client');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header style={{
      backgroundColor: '#004aad',
      color: 'white',
      padding: '15px 30px',
      minWidth: '800px',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '-5px', marginTop: '-5px'
      }}>
        {/* Left section with Logo and Navigation */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <img src="img/bybit_light.png" width="220" height="32" alt="MGB Solution Logo" style={{ marginRight: '20px' }} />
          <ul style={{
            display: 'flex',
            listStyle: 'none',
            margin: 0,
            padding: 0,
          }}>
            <li style={{ marginRight: '15px' }}>
              <Link to="/dashboard" style={{
                color: 'white',
                textDecoration: 'none',
                fontSize: '16px',
                padding: '8px 12px',
              }}>Accueil</Link>
            </li>
            <li style={{ marginRight: '15px' }}>
              <Link to="/contracts" style={{
                color: 'white',
                textDecoration: 'none',
                fontSize: '16px',
                padding: '8px 12px',
              }}>Contrat(s) P&L</Link>
            </li>
            <li style={{ marginRight: '15px' }}>
              <Link to="/trade-activity" style={{
                color: 'white',
                textDecoration: 'none',
                fontSize: '16px',
                padding: '8px 12px',
              }}>Activité de trading</Link>
            </li>
          </ul>
        </div>

        {/* Right section with User dropdown */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          {/* Profile Picture */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <div style={{
              backgroundColor: '#804a00',
              color: 'white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '18px',
              marginRight: '10px', // Ensure proper spacing from the chevron
            }}>
              {username.charAt(0).toUpperCase()}
            </div>

            {/* Chevron Icon */}
            <div onClick={toggleDropdown} style={{
              cursor: 'pointer',
              position: 'relative',
            }}>
              {dropdownOpen ? (
                <FaChevronUp style={{ fontSize: '16px', color: 'white', marginLeft: '0px' }} />
              ) : (
                <FaChevronDown style={{ fontSize: '16px', color: 'white', marginLeft: '0px' }} />
              )}
            </div>
          </div>

          {/* Dropdown Menu */}
          {dropdownOpen && (
            <div style={{
              position: 'fixed',
              top: '70px',
              right: '20px', // Adjust dropdown position relative to profile picture
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
              minWidth: '160px',
              padding: '10px 15px',
              zIndex: 1000, // Ensure the dropdown appears above other elements
            }}>
              {/* <Link to="/settings" style={{
                display: 'block',
                color: '#333',
                fontSize: '14px',
                padding: '8px 0',
                textDecoration: 'none',
              }}>Paramètres</Link> */}
              <Link to="/profile" style={{
                display: 'block',
                color: '#333',
                fontSize: '14px',
                padding: '8px 0',
                textDecoration: 'none',
              }}>Gérer mon compte</Link>
              {isAdmin && (
                <>
                  <hr style={{ margin: '10px 0', backgroundColor: '#004aad' }} />
                  <Link to="/admin" style={{
                    display: 'block',
                    color: '#333',
                    fontSize: '14px',
                    padding: '8px 0',
                    textDecoration: 'none',
                  }}>Admin</Link>
                </>
              )}
              <hr style={{ margin: '10px 0', backgroundColor: '#004aad' }} />
              <button onClick={handleLogout} style={{
                display: 'block',
                color: '#dc3545',
                fontSize: '14px',
                padding: '8px 0',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'left',
                width: '100%',
              }}>Déconnexion</button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
