import React, { useEffect, useState } from "react";
import { Navigation } from "../navigation";
import { Footer } from "../footer";

const PolicyHtml = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    // Fetch the HTML file
    fetch("/policy.html")
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error("Error loading HTML file:", error));
  }, []);

  return (
    <div>
      <div class="mention-legales-header">
        <Navigation />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{ padding: "20px" }}
      />
      <Footer />
    </div>
  );
};

export default PolicyHtml;
