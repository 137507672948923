// fetchWithAuth.js

const BASE_URL = 'https://serene-cliffs-97883-705a00c0a7b2.herokuapp.com/api'; // Replace with your actual API base URL

// Utility function to handle all fetch requests with token expiration handling
export const fetchWithAuth = async (endpoint, options = {}) => {
  const token = localStorage.getItem('token');

  // Ensure the authorization token is included in the headers if not explicitly provided
  const headers = {
    ...options.headers, // Use headers provided in options, if any
    ...(token && !options.headers?.authorization ? { authorization: token } : {}),
  };

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      ...options, // Spread all other options like method, body, etc.
      headers, // Set the headers, combining custom and default headers
    });

    // Check if the token has expired (403 status)
    if (response.status === 403) {
      // Token expired: Clear token and redirect to login page
      localStorage.removeItem('token'); // Clear token
      localStorage.removeItem('username'); // Clear user data (if stored)
      localStorage.removeItem('isAdmin');

      window.location.href = '/portail-client'; // Redirect to login page
      return Promise.reject(new Error('Session expired. Redirecting to login.'));
    }

    // Return the JSON response if no errors
    return response;
  } catch (error) {
    // Handle other errors
    console.error('API Error:', error);
    throw error;
  }
};
